import './App.css';
import NavBar from './components/NavBar';
import Contact from './components/Contact';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import Home from './components/Home';
import Careers from './components/Careers';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <div className="container">
        <Router>
          <NavBar />
          <div className='route-container'>
            <Routes>
              <Route 
                path='/' 
                element={
                  <>
                    <Helmet>
                      <title>Vidhart Events | Home</title>
                      <meta name="description" content="Welcome to Vidhart Events. Explore our event management services." />
                    </Helmet>
                    <Home />
                  </>
                }
              />
              <Route 
                path='/contact' 
                element={
                  <>
                    <Helmet>
                      <title>Contact Us - Vidhart Events</title>
                      <meta name="description" content="Get in touch with Vidhart Events for your event planning needs." />
                    </Helmet>
                    <Contact />
                  </>
                } 
              />
              <Route 
                path='/aboutus' 
                element={
                  <>
                    <Helmet>
                      <title>About Us - Vidhart Events</title>
                      <meta name="description" content="Learn more about Vidhart Events, our mission, and our team." />
                    </Helmet>
                    <AboutUs />
                  </>
                } 
              />
              <Route 
                path='/careers' 
                element={
                  <>
                    <Helmet>
                      <title>Careers - Vidhart Events</title>
                      <meta name="description" content="Join the Vidhart Events team and help us create memorable experiences." />
                    </Helmet>
                    <Careers />
                  </>
                } 
              />
            </Routes>
          </div>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;
