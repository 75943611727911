import React, { useState, useEffect, useRef, useCallback } from "react";
import { Helmet } from "react-helmet";
import "../style/Home.css";
import companyLogo from "../assets/companyLogo.jpg";
import { NavLink } from "react-router-dom";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import work1 from "../assets/Works/work1.jpg";
import work2 from "../assets/Works/work2.jpg";
import work3 from "../assets/Works/work3.jpg";

import logos from "./subComponents/logo";

import eventsImage from "../assets/events.svg";
import conferencesImage from "../assets/conferences.svg";
import productLaunchesImage from "../assets/product-launches.svg";
import dealerMeetsImage from "../assets/dealer-meets.svg";
import exhibitionsImage from "../assets/exhibitions.svg";
import brandActivationsImage from "../assets/brand-activations.svg";
import retailBrandingImage from "../assets/retail-branding.svg";
import videoProductionImage from "../assets/video-production.svg";

gsap.registerPlugin(ScrollTrigger);

const content = [
  { image: work1, text: "Innovative Designs for Your Business" },
  { image: work2, text: "Creating Memorable Experiences" },
  { image: work3, text: "Exhibition Stall Fabrication Experts" },
];

const services = [
  { image: eventsImage, name: "Events", link: "/events" },
  { image: conferencesImage, name: "Conferences", link: "/Conferences" },
  {
    image: productLaunchesImage,
    name: "Product Launches",
    link: "/Product-Launches",
  },
  { image: dealerMeetsImage, name: "Dealer Meets", link: "/Dealer-Meets" },
  { image: exhibitionsImage, name: "Exhibitions", link: "/Exhibitions" },
  {
    image: brandActivationsImage,
    name: "Brand Activations",
    link: "/Brand-Activations",
  },
  {
    image: retailBrandingImage,
    name: "Retail Branding",
    link: "/Retail-Branding",
  },
  {
    image: videoProductionImage,
    name: "Video Production",
    link: "/Video-Production",
  },
];

export default function Home() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const clientsRef = useRef(null);

  const handleAnimation = useCallback(() => {
    const clientElements =
      clientsRef.current.querySelectorAll(".honeycomb-cell");

    gsap.fromTo(
      clientElements,
      { opacity: 0, y: 100 },
      {
        opacity: 1,
        y: 0,
        stagger: 0.2,
        duration: 0.7,
        scrollTrigger: {
          trigger: clientsRef.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, [clientsRef]);

  useEffect(() => {
    handleAnimation();
  }, [handleAnimation]);

  const updateIndex = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % content.length);
  }, [content.length]);

  // Use useEffect with cleanup to start and clear the interval
  useEffect(() => {
    const interval = setInterval(updateIndex, 4000);
    return () => clearInterval(interval);
  }, [updateIndex]);

  return (
    <div className="home-container">
      {/* <Helmet>
        <title>Vidhart Events | Home</title>
        <meta
          name="description"
          content="Vidhart Events offers top-notch exhibition stall design and event management services in India. Explore our innovative designs and expert team."
        />
        <meta
          name="keywords"
          content="Vidhart Events, Exhibition Stall Design, Event Management, India, Brand Activations, Retail Branding"
        />
      </Helmet> */}
      <div className="carousel-container">
        <div className="carousel-content" key={currentIndex}>
          <img
            src={content[currentIndex].image}
            alt={content[currentIndex].text}
            className="carousel-image"
          />
          <div className="carousel-text">{content[currentIndex].text}</div>
        </div>
      </div>
      <div className="content-container">
        <header className="home-header">
          <img src={companyLogo} alt="Company Logo" className="home-logo" />
          <h1>HOME</h1>
        </header>

        <section className="creative-designer">
          <h2 className="exhibition-hall-content">
            CREATIVE EXHIBITION STALL DESIGNER
          </h2>
          <p>
            Are you participating in an exhibition and you require a stage to
            advertise your business & create brand awareness? If you’re one of
            the people who are in need to organize a magnificent mega event but
            are clueless with the entire exhibition stall design, construction,
            and management, then you’re at the right place. The reasons for
            participating in an exhibition are many. If you go out and search
            for an exhibition stall design company in India, you’ll find a long
            list of Fabricators! But Vidhart Events is different.
          </p>
          <p>We do the work with art</p>
          <p>Vidhart = With heart</p>
          <img src={work1} alt="work Design 1" className="work-image" />
        </section>
      </div>
      <section
        className="clients"
        style={{ height: "45rem", marginBottom: "3rem" }}
        ref={clientsRef}
      >
        <h2 className="">OUR CLIENTS</h2>{" "}
        <div className="honeycomb-grid">
          {logos.map((client, index) => (
            <div key={index} className="honeycomb-cell">
              <img
                src={client.image}
                alt={`Client ${index + 1}`}
                className="client-logo"
              />
            </div>
          ))}
        </div>
      </section>
      <div className="content-container">
        <section className="parameters">
          <h2 className="exhibition-stall-h2">
            WHAT MAKES US THE TOP EXHIBITION STALL FABRICATORS AND EVENT
            MANAGEMENT IN INDIA?
          </h2>
          <ul className="exhibition-hall-content">
            <li>
              <h3>EXPERIENCED TEAM</h3>
              <p>
                Our team of experienced Architects, genius Designers, and expert
                Technicians build the stall with utmost efficiency and
                precision.
              </p>

              <img
                src={work2}
                alt="Futuristic Design 2"
                className="work-image"
              />
            </li>
            <li>
              <h3>INNOVATIVE DESIGN</h3>
              <p>
                Our stunning trade show construction will compel you to consider
                innovation and exclusiveness to be synonymous with Vidhart
                Events.
              </p>
            </li>
            <li>
              <h3>BEFORE TIME EXECUTION</h3>
              <p>
                We guarantee to deliver you the premium stall construction way
                before the event starts, even in record-breaking time.
              </p>
            </li>
            <li>
              <h3>AFTER SALES SUPPORT</h3>
              <p>
                Our support is a constant one you get; right from designing to
                dismantling and additionally, during the events.
              </p>
            </li>
            <li>
              <h3>IN-HOUSE WORKSHOP</h3>
              <p>
                We have our in-house workshop equipped with the latest
                technology machinery and expert operators for uninterrupted
                services.
              </p>
            </li>
          </ul>
          <img src={work3} alt="Futuristic Design 3" className="work-image" />
        </section>

        <section className="services">
          <h2 className="services-header">SERVICES</h2>
          <div className="services-container">
            {services.map((service, index) => (
              <NavLink key={index} to={service.link} className="service-card">
                <div className="service-img-container">
                  <img
                    src={service.image}
                    alt={service.name}
                    className="service-image"
                  />
                  <div className="service-name">{service.name}</div>
                </div>
              </NavLink>
            ))}
          </div>
        </section>

        <section className="contact-info">
          <h2>Contact Info</h2>
          <p>Email ID: vidhartevents@gmail.com</p>
          <p>Mobile No: +91 9795440451 / +91 6299356381</p>
          <p>
            Office Add: 05FF, Laxmi Place, Atta, Noida - 201301, Delhi NCR,
            India.
          </p>
        </section>

        <footer className="home-footer">
          <div className="useful-links">
            <h3>Useful Links</h3>
            <ul>
              <NavLink to="aboutus" className="page-links">
                About Us
              </NavLink>
              <NavLink to="contact" className="page-links">
                Contact Us
              </NavLink>
            </ul>
          </div>
          <div className="company-info">
            <p>Vidhart Events And Exhibitions Private Limited</p>
            <p>CIN: U82300UP2023PTC187006</p>
            <p>GSTIN: 09AAJCV5756A1Z5</p>
          </div>
        </footer>
      </div>
    </div>
  );
}
