import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "../style/Footer.css";

export default function Footer() {
  return (
    <footer className="footer mt-auto py-3">
      <Container>
        <Row>
          <Col md={4} className="footer-col">
            <h5>About Us</h5>
            <p>
              We are a company dedicated to providing the best services for our customers.
              Our goal is to always exceed expectations and deliver high-quality products.
            </p>
          </Col>
          <Col md={4} className="footer-col">
            <h5>Contact Information</h5>
            <ul className="list-unstyled">
              <li>Phone: +919795440451, +916299356381, +916207455782</li>
              <li>aman.vidhart@gmail.com
             anand.vidhart@gmail.com adityans739@gmail.com</li>
              <li>Delhi Office - 05 FF, Laxmi Palace, Atta Market, Noida, U.P.,  Delhi Ncr. India</li>
            </ul>
          </Col>
          <Col md={4} className="footer-col">
            <h5>Follow Us</h5>
            <ul className="list-unstyled social-icons">
              <li><a href="https://www.facebook.com/p/Vidhart-Exhibition-61550746074384" target='_blank'><i className="fab fa-facebook-f"></i></a></li>
              <li><a href="#!" target='_blank'><i className="fab fa-twitter"></i></a></li>
              <li><a href="https://www.instagram.com/vidhart_events?igsh=b2k2OTVjZHl5ZHow" target='_blank'><i className="fab fa-instagram"></i></a></li>
              <li><a href="https://www.linkedin.com/in/vidhart-events-and-exhibitions-private-limited-57609a315" target='_blank'><i className="fab fa-linkedin-in"></i></a></li>
              <li><a href='https://wa.me/+916207455782' target='_blank'><i className='fab fa-whatsapp'></i></a></li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="text-center py-3">
            <p>&copy; 2024 Vidhart Events and Exhibition Pvt Ltd. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
