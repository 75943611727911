import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import "../style/Careers.css";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getNames } from "country-list";
import { ToastContainer, toast } from "react-toastify";
import auth from "./config";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";

export default function Careers() {
  const [isLoading, setIsLoading] = useState(false);

  const onError = (result) => {
    toast.error("Please enter valid data", {
      position: "top-right",
    });
  };

  const onSuccess = (result) => {
    toast.success(result.message, {
      theme: "colored",
      position: "top-right",
    }); // Show success message using react-toast
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const countries = getNames().map((country) => ({
    label: country,
    value: country,
  }));

  const onSubmit = async (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("email", data.email);
    formData.append("phoneNumber", data.phoneNumber);
    formData.append("country", data.country.label);
    formData.append("resume", data.resume[0]); // Assuming data.resume is the file input
    formData.append("coverLetter", data.coverLetter);

    try {
      const response = await fetch(`${auth.backend}/careers`, {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      if (response.ok) {
        onSuccess(result);
        reset(); // Reset the form after successful submission
      } else {
        onError(result);
        throw new Error(result.error); // Throw error to be caught in the catch block
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while sending the form data. Please try again.");
    } finally {
      setIsLoading(false); // Always hide the loader after submission attempt
    }
  };

  return (
    <div className="careers-container">
      {isLoading && <Loader />}
      <ToastContainer />
      <div className="careers-content">
        <h1>CAREERS</h1>
        <div className="keep-in-touch">
          <h3>KEEP IN TOUCH </h3>
          <h3>Make your career with us.</h3>
        </div>
        <hr />
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="careers-form"
          encType="multipart/form-data"
        >
          <div className="form-row">
            <div className="input-container">
              <label>First Name</label>
              <input
                type="text"
                {...register("firstName", {
                  required: "First name is required",
                })}
              />
              {errors.firstName && <p>{errors.firstName.message}</p>}
            </div>
            <div className="input-container">
              <label>Last Name</label>
              <input
                type="text"
                {...register("lastName", { required: "Last name is required" })}
              />
              {errors.lastName && <p>{errors.lastName.message}</p>}
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <label>Email Address</label>
              <input
                type="email"
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Invalid email address",
                  },
                })}
              />
              {errors.email && <p>{errors.email.message}</p>}
            </div>
            <div className="input-container">
              <label>Phone Number</label>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    country={"us"}
                    enableAreaCodes={true}
                    enableTerritories={true}
                    containerClass="phone-input-container"
                    inputClass="phone-input"
                  />
                )}
                rules={{ required: "Phone number is required" }}
              />
              {errors.phoneNumber && <p>{errors.phoneNumber.message}</p>}
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <label>Country</label>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <Select {...field} options={countries} />
                )}
                rules={{ required: "Country is required" }}
              />
              {errors.country && <p>{errors.country.message}</p>}
            </div>
            <div className="input-container">
              <label>Upload Resume</label>
              <input
                className="resume-uploader"
                type="file"
                {...register("resume", { required: "Resume is required" })}
              />
              {errors.resume && <p>{errors.resume.message}</p>}
            </div>
          </div>
          <div className="form-row">
            <div className="input-container cover-letter">
              <label>Cover Letter</label>
              <textarea
                {...register("coverLetter", {
                  required: "Cover letter is required",
                })}
              ></textarea>
              {errors.coverLetter && <p>{errors.coverLetter.message}</p>}
            </div>
          </div>
          <button type="submit" class="button">
            <span class="button_lg">
            <span class="button_sl"></span>
            <span class="button_text">Submit</span>
            </span>
          </button>
        </form>
        <hr />
        <p>
          Vidhart Events Management and stall design is a leading exhibition
          stall design company. We provide exhibition stall design, exhibition
          stall fabrication, and brand management. We have done Lots of
          International Exhibition stall design projects.
        </p>
      </div>
    </div>
  );
}
