import React, {useState} from "react";
import { useForm, Controller } from "react-hook-form";
import "../style/Contact.css";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getNames } from "country-list";
import auth from "./config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";

export default function Contact() {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onError = (result) => {
    toast.error("Please enter valid data", {
      position: "top-right",
    });
  };

  const onSuccess = (result) => {
    toast.success(result.message, {
      theme: "colored",
      position: "top-right",
    }); // Show success message using react-toast
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const countries = getNames().map((country) => ({
    label: country,
    value: country,
  }));

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    try {
      const response = await fetch(`${auth.backend}/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();
      if (response.ok) {
        onSuccess(result);
        reset();
      } else {
        onError(result);
        throw new Error(result.error); // Throw error to be caught in the catch block
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while sending the form data. Please try again.");
    }
    finally {
      setIsSubmitting(false )
    }
  };

  return (
    <div className="parent-contact-container">
      {isSubmitting && <Loader />}
      <div className="contact-box">
        <h1 className="contact-label">Contact Us</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-container">
            <label>First Name</label>
            <input
              type="text"
              {...register("firstName", { required: "First name is required" })}
            />
            {errors.firstName && <p>{errors.firstName.message}</p>}
          </div>

          <div className="input-container">
            <label>Last Name</label>
            <input
              type="text"
              {...register("lastName", { required: "Last name is required" })}
            />
            {errors.lastName && <p>{errors.lastName.message}</p>}
          </div>

          <div className="input-container">
            <label>Country</label>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                <Select className="select-box" 
                {...field} 
                options={countries} 
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value} 
                />
              )}
              rules={{ required: "Country is required" }}
            />
            {errors.country && <p>{errors.country.message}</p>}
          </div>

          <div className="input-container">
            <label>Phone Number</label>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  {...field}
                  country={"us"}
                  enableAreaCodes={true}
                  enableTerritories={true}
                />
              )}
              rules={{ required: "Phone number is required" }}
            />
            {errors.phoneNumber && <p>{errors.phoneNumber.message}</p>}
          </div>

          <div className="input-container">
            <label>Email</label>
            <input
              type="email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: "Invalid email address",
                },
              })}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>

          <button type="submit" class="button">
            <span class="button_lg">
            <span class="button_sl"></span>
            <span class="button_text">Submit</span>
            </span>
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
