import logo1 from "../../assets/Clients/Logos/logo1.png";
import logo2 from "../../assets/Clients/Logos/logo2.png";
import logo3 from "../../assets/Clients/Logos/logo3.png";
import logo4 from "../../assets/Clients/Logos/logo4.png";
import logo5 from "../../assets/Clients/Logos/logo5.png";
import logo6 from "../../assets/Clients/Logos/logo6.png";
import logo7 from "../../assets/Clients/Logos/logo7.png";
import logo8 from "../../assets/Clients/Logos/logo8.png";
import logo9 from "../../assets/Clients/Logos/logo9.png";
import logo10 from "../../assets/Clients/Logos/logo10.png";
import logo11 from "../../assets/Clients/Logos/logo11.png";
import logo12 from "../../assets/Clients/Logos/logo12.png";
import logo13 from "../../assets/Clients/Logos/logo13.png";
import logo14 from "../../assets/Clients/Logos/logo14.png";
// import logo15 from "../../assets/Clients/Logos/logo15.png";
import logo16 from "../../assets/Clients/Logos/logo16.png";
import logo17 from "../../assets/Clients/Logos/logo17.png";
import logo18 from "../../assets/Clients/Logos/logo18.png";
import logo19 from "../../assets/Clients/Logos/logo19.png";
import logo20 from "../../assets/Clients/Logos/logo20.png";
import logo21 from "../../assets/Clients/Logos/logo21.png";
import logo22 from "../../assets/Clients/Logos/logo22.png";

const logos  = [
    {image: logo1},
    {image: logo2},
    {image: logo3},
    {image: logo4},
    {image: logo5},
    {image: logo6},
    {image: logo7},
    {image: logo8},
    {image: logo9},
    {image: logo10},
    {image: logo11},
    {image: logo12},
    {image: logo13},
    {image: logo14},
    // {image: logo15},
    {image: logo16},
    {image: logo17},
    {image: logo18},
    {image: logo19},
    {image: logo20},
    {image: logo21},
    {image: logo22}
]
export default logos;