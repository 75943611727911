import "../style/NavBar.css";
import logo from "../assets/Clients/Logos/logo1.png";

import { NavLink } from "react-router-dom";

import React, { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";

export default function NavBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <div>
      <div className="hamburger" onClick={toggleMenu}>
        &#9776;
      </div>
      <div className={`parent-navbar ${isMenuOpen ? "open" : ""}`}>
        <nav>
          <ul className="navbar-container">
            <li className="contact-link">
              <NavLink to="/" className="contact-nav" activeClassName="active">
                Home
              </NavLink>
            </li>
            <li className="nav-item dropdown">
              <span className="dropdown-nav">Services &#9662;</span>
              <ul className="dropdown-menu">
                {[
                  "Events",
                  "Conferences",
                  "Product Launches",
                  "Dealer Meets",
                  "Exhibitions",
                  "Brand Activations",
                  "Retail Branding",
                  "Video Production",
                ].map((service) => (
                  <li key={service}>
                    <NavLink
                      to={`/${service.replace(/\s+/g, "")}`}
                      className="dropdown-item"
                    >
                      {service}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li>

            <li className="contact-link">
              <NavLink
                to="/contact"
                className="contact-nav"
                activeClassName="active"
              >
                Contact
              </NavLink>
            </li>
            <li className="contact-link">
              <NavLink
                to="/careers"
                className="contact-nav"
                activeClassName="active"
              >
                Careers
              </NavLink>
            </li>
            <li className="contact-link">
              <NavLink
                to="/AboutUs"
                className="contact-nav"
                activeClassName="active"
              >
                About Us
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
