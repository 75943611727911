import React, { useEffect, useRef } from "react";
import "../style/AboutUs.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import stand1 from "../assets/stand1.jpg";
import stand2 from "../assets/stand2.jpg";

import work1 from "../assets/Works/work1.jpg";
import work2 from "../assets/Works/work2.jpg";
import work3 from "../assets/Works/work3.jpg";
import work4 from "../assets/Works/work4.jpg";
import work5 from "../assets/Works/work5.jpg";
import work6 from "../assets/Works/work6.jpg";
import work7 from "../assets/Works/work7.jpg";
import work8 from "../assets/Works/work8.jpg";
import work9 from "../assets/Works/work9.jpg";
import work10 from "../assets/Works/work10.jpg";
import work11 from "../assets/Works/work11.jpg";
import work12 from "../assets/Works/work12.jpg";

gsap.registerPlugin(ScrollTrigger);

export default function AboutUs() {
  const galleryRef = useRef(null);

  useEffect(() => {
    const galleryElements = galleryRef.current.querySelectorAll(".gallery-image");

    gsap.fromTo(
      galleryElements,
      { opacity: 0, y: 50, scale: 1.5 },
      {
        opacity: 1,
        y: 0,
        stagger: 0.2,
        duration: 1,
        scale: 1,
        scrollTrigger: {
          trigger: galleryRef.current,
          start: "top 80%",
          end: "bottom 20%",
          toggleActions: "play",
        },
      }
    );
  }, []);

  
  return (
    <div className="about-us-container">
      <h1 className="about-us-h1">ABOUT US</h1>

      <section className="know-us-better">
        <h2 className="about-us-h2">KNOW US BETTER</h2>
        <p className="plain-text">
          Driven by passion, Vidhart Events believe in creating memorable
          experiences! Vidhart Events designs and executes exhibition stalls,
          retail merchandising units and events; right from concept to
          execution. Having served brands across a wide gamut of industries
          including, Pharmaceuticals & Chemicals, Automation & Machinery, Power
          & Energy, Real Estate, FMCG and many more; we deliver the best always!
        </p>
        <p className="plain-text">
          We, boast of a diverse team of event and exhibition planners, media,
          and PR people. So, no matter what kind of a need your business has, we
          pride ourselves on delivering a first-class service to each and every
          one of our clients.
        </p>
      </section>

      <section className="what-we-do">
        <h2 className="about-us-h2">WHAT WE DO</h2>
        <p className="plain-text">
          We share your dreams – There's a bit of innovation in everything we
          do! We assist brands personify themselves in a manner that their
          customers can identify with. With comprehensive networks of
          established partners around the globe, Vidhart Events provide design
          and execution for exhibition stalls, retail merchandising units and
          events. With a proven track record of over 85% client retention ratio,
          we have executed exhibitions across 6 continents.
        </p>
      </section>

      <section className="why-choose-us">
        <h2 className="about-us-h2">WHY CHOOSE US</h2>
        <p className="plain-text">
          We deliver what we promise! At Vidhart Events, we infuse creativity
          and a collaborative working style to conceptualise exclusive designs
          for our clients that aid effective customer engagements. We converge
          strategic planning and meticulous execution to deliver solutions aimed
          at effectively communicating your brand to the relevant target
          audience.
        </p>
      </section>

      <section className="who-we-are">
        <h2 className="about-us-h2">WHO WE ARE</h2>
        <div className="mission">
          <h3>Our Mission</h3>
          <ul>
            <li>
              Our mission is to create the world's most meaningful and memorable
              experiences, and to meet and exceed the expectations of our
              clients through ground-breaking concepts and the delivery of
              outstanding service.
            </li>
          </ul>
        </div>
        <div className="mission">
          <h3>Our Vision</h3>
          <ul>
            <li>
              To be the top Event Exhibitions Management Company, and help our
              clients attain their goal through the development and execution of
              tailored and accurately planned events and exhibitions.
            </li>
          </ul>
        </div>
        <div className="principals">
          <h3>Our Principals</h3>
          <ul>
            <li>
              At Vidhart Events, we always put your organization first. We
              approach every project with care and accuracy. Irrespective of the
              size and scope, we treat every event and exhibition as an
              opportunity to display your brand, thus, develop an all-inclusive
              plan to make sure that your event and exhibition happen on time
              and within budget. At Vidhart Events, we make it a point to first
              learn about your business, we understand your challenges, and we
              plan events and exhibitions to fulfill your objectives.
            </li>
          </ul>
        </div>
        <div className="team">
          <h3>Our Team</h3>
          <ul>
            <li>
              Our team of highly experienced and dedicated professionals at
              Vidhart Events understands that a well-executed event and
              exhibition can help an organization or an individual to achieve
              their strategic vision, and gain more and more clients. That is
              why our experts try their best to get to know your company inside
              and out and manage each aspect to organise excellent event and
              exhibition.
            </li>
          </ul>
        </div>
        <div className="strength">
          <h3>Our Strength</h3>
          <ul>
            <li>
              We have a highly experienced team of veteran industry
              professionals, which makes sure that your event and exhibition
              goes right the first time
            </li>
            <li>
              We are proficient when it comes to designing the entire event and
              exhibition and including important branding elements flawlessly.
            </li>
            <li>
              We are completely transparent and will never make false promises
              to our clients.
            </li>
            <li>
              Vidhart Events have a huge pool and vendors and other important
              people in our contact
            </li>
            <li>
              An event and/or exhibition planned by us will give your sales team
              an opportunity to shine and attract the right people.
            </li>
            <li>
              Our huge experience in the industry ensures that all your
              employees and clients will always be happy with you.
            </li>
          </ul>
        </div>
      </section>

      <section className="our-clients">
        <h2 className="about-us-h2">OUR CLIENTS</h2>
        <p className="plain-text">
          We feel pleased to be associated with a widely esteemed client list
          that includes names reflecting a strong world brand and their seasoned
          presence in their respective industries. We have established ourselves
          as what we are, owing to our clientele and their confidence in us for
          their rising needs.
        </p>
      </section>

      <section className="gallery" ref={galleryRef}>
        <h2 className="about-us-h2">Our Work</h2>
        <div className="images">
          <img src={work11} alt="Stand 1" className="gallery-image" />
          <img src={work12} alt="Stand 2" className="gallery-image" />
          <img src={work1} alt="Stand 1" className="gallery-image" />
          <img src={work9} alt="Stand 1" className="gallery-image" />
        </div>
        <div className="images">
          <img src={work2} alt="Stand 2" className="gallery-image" />
          <img src={work3} alt="Stand 1" className="gallery-image" />
          <img src={work4} alt="Stand 2" className="gallery-image" />
          <img src={work10} alt="Stand 2" className="gallery-image" />
        </div>
        <div className="images">
          <img src={work5} alt="Stand 1" className="gallery-image" />
          <img src={work6} alt="Stand 2" className="gallery-image" />
          <img src={work7} alt="Stand 1" className="gallery-image" />
          <img src={work8} alt="Stand 2" className="gallery-image" />
        </div>
      </section>
    </div>
  );
}
