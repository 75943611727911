import React from "react";
import "../style/Loader.css"; // Make sure to create this CSS file

const Loader = () => (
  <div className="loader-overlay">
    <div className="loader"></div>
  </div>
);

export default Loader;
